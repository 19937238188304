import { runInAction, makeAutoObservable } from "mobx";

import { DictionaryValue } from "../model";

import { StatisticsDictionaryApi } from "shared/api";
import { Result } from "shared/api/request";

const { getOffers, getTimeZone, getAffiliates, getOpponents, getOpponentsAffiliates, getOfferBrandAdvertisers } =
  StatisticsDictionaryApi();

export class StatisticsDictionaryStore {
  offers: DictionaryValue = null;
  timeZone: DictionaryValue = null;
  affiliates: DictionaryValue = null;
  opponents: DictionaryValue = null;
  opponentsAffiliates: DictionaryValue = null;
  brandAdvertisers: DictionaryValue | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  loadOffers = async () => {
    if (!this.offers) {
      const result: Result = await getOffers();
      runInAction(() => {
        if (result.isSuccess) {
          this.offers = [...result.result];
        } else {
          this.offers = [];
        }
      });
    }
  };

  loadAffiliates = async () => {
    if (!this.affiliates) {
      const result: Result = await getAffiliates();
      runInAction(() => {
        if (result.isSuccess) {
          this.affiliates = [...result.result];
        } else {
          this.affiliates = [];
        }
      });
    }
  };

  loadTimeZone = async () => {
    if (!this.timeZone) {
      const result: Result = await getTimeZone();
      runInAction(() => {
        if (result.isSuccess) {
          this.timeZone = [...result.result];
        } else {
          this.timeZone = [];
        }
      });
    }
  };

  loadOpponents = async () => {
    if (!this.opponents) {
      const result: Result = await getOpponents();
      runInAction(() => {
        if (result.isSuccess) {
          this.opponents = [...result.result];
        } else {
          this.opponents = [];
        }
      });
    }
  };

  loadOpponentsAffiliates = async (id: number) => {
    const result: Result = await getOpponentsAffiliates(id);
    runInAction(() => {
      if (result.isSuccess) {
        this.opponentsAffiliates = [...result.result];
      } else {
        this.opponentsAffiliates = [];
      }
    });
  };

  loadBrandAdvertisers = async () => {
    const result = await getOfferBrandAdvertisers();
    runInAction(() => {
      if (result.isSuccess) {
        this.brandAdvertisers = [...result.result];
      } else {
        this.brandAdvertisers = [];
      }
    });
  };
}
