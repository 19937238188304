import { makeAutoObservable } from "mobx";

import { PaginationModel } from "./model";

export class PaginationStore implements PaginationModel {
  page = 1;
  pageSize = 10;
  totalCount = 0;

  constructor(pageSize = 10) {
    makeAutoObservable(this);

    this.pageSize = pageSize;
  }

  getPage = () => {
    return this.page;
  };

  getPageSize = () => {
    return this.pageSize;
  };

  getTotalCount = () => {
    return this.totalCount;
  };

  updatePage = (page: number | null) => {
    if (page) {
      this.page = page;
    }
  };

  updatePageSize = (pageSize: number) => {
    this.pageSize = pageSize;
  };

  updateTotalCount = (totalCount: number) => {
    this.totalCount = totalCount;
  };
}
