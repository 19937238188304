import { ReactComponent as ArrowIcon } from "@material-symbols/svg-400/rounded/arrow_forward_ios.svg";

import {
  StatisticOffersValue,
  StatisticOffersPreviewValue,
  StatisticAffiliatesValue,
  StatisticAffiliatesPreviewValue,
  StatisticOpponentsValue,
  StatisticOpponentsPreviewValue,
  StatisticOpponentAffiliatesValue,
  StatisticOpponentAffiliatesPreviewValue,
} from "./model";
import classes from "./styles/Statistics.module.scss";

import { Link } from "shared/ui/Link";
import { DefaultOption } from "shared/ui/Select";
import { getOption, formatNumber } from "shared/utils";

export const getValueFromOptions = (item: number | string | undefined, options: DefaultOption[]) => {
  if (item) return getOption(item, options);
};

export const getValuesFromOptions = (items: number[] | string[] | undefined, options: DefaultOption[]) => {
  if (items) {
    const result: DefaultOption[] = [];
    items.forEach((item) => {
      const findValue = getValueFromOptions(item, options);
      if (findValue) result.push(findValue);
    });
    return result;
  }
};

export const getValueFromFilters = (value: DefaultOption | number | string | undefined) => {
  if (value && typeof value === "object") return value.value;
  return value;
};

export const getValuesFromFilters = (values: DefaultOption[] | number[] | string[] | undefined) => {
  if (values) return values.map((value) => getValueFromFilters(value));
};

export const getFormatStatisticOffersAndAffiliatesValue = (
  value: StatisticOffersValue | StatisticAffiliatesValue,
): StatisticOffersPreviewValue | StatisticAffiliatesPreviewValue => {
  return {
    ...value,
    clicksUnique: formatNumber(value.clicksUnique),
    clicksTotal: formatNumber(value.clicksTotal),
    allConversions: formatNumber(value.allConversions),
    profitableConversions: formatNumber(value.profitableConversions),
    pendingConversions: formatNumber(value.pendingConversions),
    rejectedConversions: formatNumber(value.rejectedConversions),
    cr: formatNumber(value.cr),
    ar: formatNumber(value.ar),
    epc: formatNumber(value.epc),
    epl: formatNumber(value.epl),
  };
};

export const getFormatStatisticOpponentsData = (data: StatisticOpponentsValue[]): StatisticOpponentsPreviewValue[] => {
  return data.map((item, i) => ({
    key: `opponent-${item.id}-${i}`,
    name: (
      <Link to={`/statistics/opponents/${item.id}`} className={classes.link} variant="black">
        {item.name}
        <ArrowIcon className={classes.icon} />
      </Link>
    ),
    id: item.id,
    affiliatesTotal: formatNumber(item.affiliatesTotal),
    cr: formatNumber(item.cr),
    ar: formatNumber(item.ar),
    epc: formatNumber(item.epc),
  }));
};

export const getFormatStatisticOpponentAffiliatesData = (
  data: StatisticOpponentAffiliatesValue[],
): StatisticOpponentAffiliatesPreviewValue[] => {
  return data.map((item, i) => ({
    id: item.id,
    key: `opponent-affiliate-${item.id}-${i}`,
    clicksTotal: formatNumber(item.clicksTotal),
  }));
};
