import { makeAutoObservable, runInAction, computed } from "mobx";

import { StatisticOpponentAffiliatesValue, StatisticOpponentAffiliatesPreviewValue } from "../model";
import { getFormatStatisticOpponentAffiliatesData } from "../utils";

import { StatisticsOpponentsApi } from "shared/api";
import { PaginationStore } from "shared/store/Pagination";
import { SortingStore } from "shared/store/Sorting";

const { getOpponentsAffiliates, getOpponent } = StatisticsOpponentsApi();

export class StatisticOpponentAffiliatesStore {
  pagination = new PaginationStore(10);
  sorting = new SortingStore("clicksTotal", 3);
  data: StatisticOpponentAffiliatesValue[] | null = null;
  opponentName = "";
  name = "";
  loading = false;

  constructor() {
    makeAutoObservable(this, {
      getFormatData: computed,
    });
  }

  get getFormatData(): StatisticOpponentAffiliatesPreviewValue[] {
    if (this.data) {
      return getFormatStatisticOpponentAffiliatesData(this.data);
    }
    return [];
  }

  loadData = async (id: string, filters: Record<string, any>) => {
    this.loading = true;
    const result = await getOpponentsAffiliates({
      id,
      ...filters,
      name: this.name,
      page: this.pagination.page,
      pageSize: this.pagination.pageSize,
      sortParam: this.sorting.sortParam,
      sortDirection: this.sorting.sortDirection || null,
    });
    runInAction(() => {
      if (result.isSuccess) {
        this.data = [...result.result.items];
        this.pagination.updateTotalCount(result.result.pagination.totalCount);
      } else if (result.isError) {
        this.data = [];
        this.pagination.updatePage(1);
        this.pagination.updateTotalCount(0);
      }
      this.loading = false;
    });
  };

  loadOpponentName = async (id: number) => {
    const result = await getOpponent(id);
    runInAction(() => {
      if (result.isSuccess) {
        this.opponentName = result.result.name;
      }
    });
  };

  updateName = (name: string) => {
    this.name = name;
  };
}
