import { runInAction, makeAutoObservable, computed } from "mobx";

import { StatisticOffersValue, StatisticOffersPreviewValue } from "../model";
import { getFormatStatisticOffersAndAffiliatesValue } from "../utils";

import { StatisticsOffersApi } from "shared/api";
import { PaginationStore } from "shared/store/Pagination";
import { SortingStore } from "shared/store/Sorting";

const { getData } = StatisticsOffersApi();

export class StatisticOffersStore {
  pagination = new PaginationStore(10);
  sorting = new SortingStore("id", 4);
  data: StatisticOffersValue[] | null = null;
  summary: StatisticOffersValue | null = null;
  name = "";
  loading = true;

  constructor() {
    makeAutoObservable(this, {
      getFormatData: computed,
      getFormatSummary: computed,
    });
  }

  get getFormatData(): StatisticOffersPreviewValue[] {
    if (this.data) {
      return this.data.map((item, i) =>
        getFormatStatisticOffersAndAffiliatesValue({
          ...item,
          key: `offer-${item.id}-${i}`,
        }),
      );
    }
    return [];
  }

  get getFormatSummary(): StatisticOffersPreviewValue | undefined {
    if (this.summary) {
      return getFormatStatisticOffersAndAffiliatesValue({
        ...this.summary,
        id: "",
        name: "Всего",
        key: "offers-summary",
      });
    }
  }

  loadData = async (filters: Record<string, any>) => {
    this.loading = true;
    const result = await getData({
      ...filters,
      name: this.name,
      page: this.pagination.page,
      pageSize: this.pagination.pageSize,
      sortParam: this.sorting.sortParam,
      sortDirection: this.sorting.sortDirection || null,
    });
    runInAction(() => {
      if (result.isSuccess) {
        this.data = [...result.result.items];
        this.summary = this.data.length ? { ...result.result.summary } : null;
        this.pagination.updateTotalCount(result.result.pagination.totalCount);
      } else if (result.isError) {
        this.data = [];
        this.summary = null;
        this.pagination.updatePage(1);
        this.pagination.updateTotalCount(0);
      }
      this.loading = false;
    });
  };

  updateName = (name: string) => {
    this.name = name;
  };
}
