import { MainProvider } from "app/providers/MainProvider";
import { LayoutRoutes } from "app/routes";

import { NotificationsProvider } from "shared/ui/Notification";

import "reset-css";
import "./App.scss";

function App() {
  return (
    <NotificationsProvider>
      <MainProvider>
        <LayoutRoutes />
      </MainProvider>
    </NotificationsProvider>
  );
}

export default App;
