export type QueryType = Record<string, any>;

export const convertQueryStringToObject = (query: string | URLSearchParams): QueryType => {
  const params = new URLSearchParams(query);
  const result: QueryType = {};
  params.forEach((value, key) => {
    const index = key.lastIndexOf("[]");
    if (index !== -1) {
      const newKey = key.substring(0, index);
      if (result[newKey] === undefined) result[newKey] = [];
      result[newKey].push(value);
    } else {
      result[key] = value;
    }
  });
  return result;
};

export const convertObjectToQueryString = (query: QueryType, excludeKeys?: string[]): string => {
  const params = new URLSearchParams();
  Object.entries(query).forEach(([key, value]) => {
    if (!excludeKeys || !excludeKeys.includes(key)) {
      if (Array.isArray(value) && value.length) {
        value.forEach((val) => {
          if (val && typeof val !== "object") params.append(`${key}[]`, val);
        });
      }
      if (typeof value !== "object" && typeof value !== "undefined" && value !== "") {
        params.append(key, value);
      }
    }
  });
  if (params.size) {
    return "?" + params.toString();
  }
  return "";
};
