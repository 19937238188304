import { FC } from "react";

import { Outlet } from "react-router-dom";

import { BaseLayout } from "../BaseLayout";

import classes from "./ProfileLayout.module.scss";

import { ProfileNavigation } from "widgets/ProfileNavigation";

import { Grid, Col } from "shared/ui/Grid";
import { Heading } from "shared/ui/Heading";

export const ProfileLayout: FC = () => {
  return (
    <BaseLayout>
      <Heading tag="h1">Профиль</Heading>
      <Grid justify="between" lg={{ direction: "column" }} className={classes.content}>
        <Col span={3} lg={{ span: 12 }}>
          <ProfileNavigation />
        </Col>
        <Col span={9} lg={{ span: 12 }}>
          <Outlet />
        </Col>
      </Grid>
    </BaseLayout>
  );
};
