import { Order } from "shared/ui/Table";

export type SortOrderType = 3 | 4 | 0;

export interface SortingModel {
  sortParam: string | null;
  sortDirection: SortOrderType;
}

export const DEFAULT_ORDER: Record<Order, SortOrderType> = {
  def: 0,
  asc: 3,
  desc: 4,
};
