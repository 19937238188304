import { makeAutoObservable, computed } from "mobx";

import { SortOrderType, DEFAULT_ORDER, SortingModel } from "./model";

import { Order } from "shared/ui/Table";

export class SortingStore implements SortingModel {
  sortParam: string | null = null;
  sortDirection: SortOrderType = 4;

  constructor(sortParam = "id", sortDirection: SortOrderType = 4) {
    makeAutoObservable(this, {
      getOrder: computed,
    });

    this.sortParam = sortParam;
    this.sortDirection = sortDirection;
  }

  get getOrder(): Order {
    const direction = Object.entries(DEFAULT_ORDER).find(([_, value]) => value === this.sortDirection);
    return direction ? (direction[0] as Order) : "def";
  }

  getSortDirection = () => {
    return this.sortDirection;
  };

  getSortParam = () => {
    return this.sortParam;
  };

  updateSortDirection = (order: Order | SortOrderType) => {
    if (typeof order === "string") {
      this.sortDirection = DEFAULT_ORDER[order];
    } else {
      this.sortDirection = order;
    }
  };

  updateSortParam = (param: string | null) => {
    this.sortParam = this.sortDirection ? param : null;
  };
}
