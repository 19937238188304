import { FC } from "react";

import cn from "classnames";
import { observer } from "mobx-react-lite";

import { routes } from "app/routes/routes";

import { Dropdown } from "../Dropdown";

import classes from "./Navigation.module.scss";

import { NavLink } from "shared/ui/NavLink";

interface Props {
  count?: number;
  mobile?: boolean;
  setOpen?: (value: boolean) => void;
}

export const Navigation: FC<Props> = observer(({ count, mobile = false, setOpen }) => {
  return (
    <div data-testid="navigation-test" className={cn(classes.navigation, mobile && classes.mobile)}>
      {routes.map((route) => {
        if (route.links && route.links.length) {
          return (
            <Dropdown
              count={route.hasCount ? count : undefined}
              setOpenBurger={setOpen}
              key={route.id}
              label={route.label}
              content={route.links ?? []}
            />
          );
        }
        return (
          <NavLink
            className={classes["nav-link"]}
            onClick={() => setOpen && setOpen(false)}
            key={route.id}
            to={route.path ?? "/"}
          >
            {route.label}
          </NavLink>
        );
      })}
    </div>
  );
});
