import { FC, useState } from "react";

import { ReactComponent as Account } from "@material-symbols/svg-400/rounded/account_circle.svg";
import { ReactComponent as Menu } from "@material-symbols/svg-400/rounded/menu.svg";
import { observer } from "mobx-react-lite";

import { useStore } from "entities/context";

import { BurgerButton } from "./components/BurgerButton";
import { Navigation } from "./components/Navigation";
import classes from "./Header.module.scss";

import { Container } from "shared/ui/Container";
import { Drawer } from "shared/ui/Drawer";
import { Link } from "shared/ui/Link";
import { Logo } from "shared/ui/Logo";

export const Header: FC = observer(() => {
  const {
    userStore: { getFullName },
  } = useStore();
  const [openBurger, setOpenBurger] = useState(false);

  return (
    <header className={classes.header}>
      <Container fluid className={classes.content}>
        <div className={classes.left}>
          <BurgerButton className={classes.burger} open={openBurger} setOpen={setOpenBurger}>
            <Menu />
          </BurgerButton>
          <Link to="/dashboard" className={classes.logo}>
            <Logo theme="light" />
          </Link>
          <Navigation />
        </div>
        <div className={classes.right}>
          <Link to="/profile" className={classes.account}>
            <span className={classes.name}>{getFullName}</span>
            <Account />
          </Link>
        </div>
        <Drawer theme="dark" position="left" open={openBurger} setOpen={setOpenBurger}>
          <Navigation mobile setOpen={setOpenBurger} />
        </Drawer>
      </Container>
    </header>
  );
});
