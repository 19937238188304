import { makeAutoObservable, runInAction, computed } from "mobx";

import { StatisticOpponentsValue, StatisticOpponentsPreviewValue } from "../model";
import { getFormatStatisticOpponentsData } from "../utils";

import { StatisticsOpponentsApi } from "shared/api";
import { PaginationStore } from "shared/store/Pagination";
import { SortingStore } from "shared/store/Sorting";

const { getOpponents } = StatisticsOpponentsApi();

export class StatisticOpponentsStore {
  pagination = new PaginationStore(10);
  sorting = new SortingStore("name", 4);
  data: StatisticOpponentsValue[] | null = null;
  name = "";
  loading = false;

  constructor() {
    makeAutoObservable(this, {
      getFormatData: computed,
    });
  }

  get getFormatData(): StatisticOpponentsPreviewValue[] {
    if (this.data) {
      return getFormatStatisticOpponentsData(this.data);
    }
    return [];
  }

  loadData = async (filters: Record<string, any>) => {
    this.loading = true;
    const result = await getOpponents({
      ...filters,
      name: this.name,
      page: this.pagination.page,
      pageSize: this.pagination.pageSize,
      sortParam: this.sorting.sortParam,
      sortDirection: this.sorting.sortDirection || null,
    });
    runInAction(() => {
      if (result.isSuccess) {
        this.data = [...result.result.items];
        this.pagination.updateTotalCount(result.result.pagination.totalCount);
      } else if (result.isError) {
        this.data = [];
        this.pagination.updatePage(1);
        this.pagination.updateTotalCount(0);
      }
      this.loading = false;
    });
  };

  updateName = (name: string) => {
    this.name = name;
  };
}
