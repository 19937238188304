import { FC, useState } from "react";

import { ReactComponent as LogoutIcon } from "@material-symbols/svg-400/rounded/logout.svg";

import { useStore } from "entities/context";

import { profileRoutes } from "app/routes/routes";

import classes from "./ProfileNavigation.module.scss";

import { Button } from "shared/ui/Button";
import { Confirm } from "shared/ui/Modal";
import { NavLink } from "shared/ui/NavLink";

export const ProfileNavigation: FC = () => {
  const [logoutConfirm, setLogoutConfirm] = useState(false);
  const {
    userStore: { logout },
  } = useStore();

  const hanldeLogout = async () => {
    await logout();
    location.replace(process.env.REACT_APP_PUBLIC_URL as string);
  };

  return (
    <>
      <div className={classes.navigation}>
        {profileRoutes.map((route) => (
          <NavLink className={classes.link} isSideMenu to={route.path || "/"} key={route.id}>
            {route.icon}
            <span>{route.label}</span>
          </NavLink>
        ))}
        <Button
          className={classes.exit}
          theme="secondary"
          variant="text"
          iconLeft={<LogoutIcon />}
          onClick={() => setLogoutConfirm(true)}
        >
          Выйти
        </Button>
      </div>

      <Confirm
        open={logoutConfirm}
        cancelBtnText="Отмена"
        setOpen={setLogoutConfirm}
        targetBtnHandler={hanldeLogout}
        showClose
        targetBtnText="Выйти"
        img={<LogoutIcon />}
        title="Уверены, что хотите выйти?"
      />
    </>
  );
};
