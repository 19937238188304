import { runInAction, makeAutoObservable, computed } from "mobx";

import { FilterValue, DefaultFilterValue } from "../model";
import { getValueFromOptions, getValuesFromOptions, getValueFromFilters, getValuesFromFilters } from "../utils";

import { StatisticsDictionaryStore } from "./dictionary";

import { StatisticsFiltersApi } from "shared/api";
import { SortOrderType } from "shared/store/Sorting";
import { convertObjectToQueryString } from "shared/utils";

const { getDefaultFilter, getFilter, saveFilter } = StatisticsFiltersApi();

export class StatisticsFiltersStore {
  dictionary = new StatisticsDictionaryStore();
  hash: string | null = null;
  filters: DefaultFilterValue = {
    affiliateIds: undefined,
    affiliatesIds: undefined,
    offerIds: undefined,
    advertisersIds: undefined,
    brandAdvertisersIds: undefined,
    dateStart: undefined,
    dateEnd: undefined,
    timezone: undefined,
  };

  constructor() {
    makeAutoObservable(this);
  }

  get isEmptyFilters() {
    return (
      this.filters.dateStart === undefined && this.filters.dateEnd === undefined && this.filters.timezone === undefined
    );
  }

  get dateAndTimezoneFromDefaultFilters() {
    return {
      timezone: getValueFromOptions(this.filters.timezone, this.dictionary.timeZone || []),
      date: {
        from: this.filters.dateStart,
        to: this.filters.dateEnd,
      },
    };
  }

  get brandAdvertisers() {
    return {
      brandAdvertisersIds: getValuesFromOptions(
        this.filters.brandAdvertisersIds,
        this.dictionary.brandAdvertisers || [],
      ),
    };
  }

  get filtersForOffers() {
    return {
      offerIds: getValuesFromOptions(this.filters.offerIds, this.dictionary.offers || []),
      ...this.dateAndTimezoneFromDefaultFilters,
      ...this.brandAdvertisers,
    };
  }

  get filtersForAffiliates() {
    return {
      affiliateIds: getValuesFromOptions(this.filters.affiliateIds, this.dictionary.affiliates || []),
      ...this.filtersForOffers,
      ...this.brandAdvertisers,
    };
  }

  get filtersForOpponents() {
    return {
      advertisersIds: getValuesFromOptions(this.filters.advertisersIds, this.dictionary.opponents || []),
      ...this.dateAndTimezoneFromDefaultFilters,
    };
  }

  get filtersForOpponentAffiliates() {
    return {
      affiliatesIds: getValuesFromOptions(this.filters.affiliatesIds, this.dictionary.opponentsAffiliates || []),
      ...this.dateAndTimezoneFromDefaultFilters,
    };
  }

  get paramsForExport() {
    return { ...this.filters, hash: this.hash };
  }

  getQueryForPage = (page: number, sortParam: string | null, sortDirection: SortOrderType): string => {
    const queryObject: Record<string, unknown> = { hash: this.hash };
    if (page && page !== 1) {
      queryObject.page = page;
    }
    if (sortParam && sortDirection) {
      queryObject.sortParam = sortParam;
      queryObject.sortDirection = sortDirection;
    }
    return convertObjectToQueryString(queryObject);
  };

  loadFilters = async () => {
    const result = await (this.hash ? getFilter(this.hash) : getDefaultFilter());
    runInAction(() => {
      if (result.isSuccess) {
        this.filters = {
          ...this.filters,
          ...result.result,
        };
      }
    });
  };

  saveFilters = async () => {
    const result = await saveFilter(this.filters);
    runInAction(() => {
      if (result.isSuccess) {
        this.hash = result.result.hash;
      }
    });
  };

  updateFilters = (newFilters: Partial<FilterValue>) => {
    const params: Record<string, unknown> = {};
    for (const key in newFilters) {
      if (key === "date") {
        params.dateStart = newFilters[key]?.from;
        params.dateEnd = newFilters[key]?.to;
      } else if (key === "timezone") {
        params[key] = getValueFromFilters(newFilters[key]);
      } else {
        const value = newFilters[key as keyof FilterValue];
        if (value && Array.isArray(value) && value.length !== 0) {
          params[key] = getValuesFromFilters(value);
        } else {
          params[key] = undefined;
        }
      }
    }
    this.filters = { ...this.filters, ...params };
  };

  updateHash = (newHash: string | null) => {
    this.hash = newHash;
  };

  resetFilters = () => {
    this.filters = {
      affiliateIds: undefined,
      affiliatesIds: undefined,
      offerIds: undefined,
      advertisersIds: undefined,
      dateStart: undefined,
      dateEnd: undefined,
      timezone: undefined,
    };
  };
}
